import React, { Component } from "react";

class GeneralToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: props.isActive,
    };
  }

  toggleButton = () => {
    this.setState(
      (prevState) => ({ isActive: !prevState.isActive }),
      () => {
        if (this.props.onToggle) {
          this.props.onToggle(this.state.isActive);
        }
      }
    );
  };

  render() {
    const { isActive } = this.state;
    return (
      <div
        className={`${this.props.size !== null ? "small-toggle-button" : "toggle-button"}  ${isActive ? "active" : ""} `}
        onClick={this.toggleButton}
      >
        <div className={`${this.props.size !== null ? "small-handler" : "handler"}`}></div>
      </div>
    );
  }
}

export default GeneralToggleButton;
