import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommentDetials from './CommentDetials'
import CommentFooter from './CommentFooter';
import CommentAction from './CommentAction';

class CommentCreateContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initSide: null,
            switchSide: this.props.switchSide,
            topBottomPosition: "top",
            topPosition: false,
            commentAnnotationContainerWidth:
                this.props.commentAnnotationContainerWidth,
            commentBoxAnnotation: this.props.commentBoxAnnotation,
            saveButtonClick: null,
            commentMedias: this.props.commentUpdate ? this.props.commentForUpdate.getMedias : [],
            selectedCategory: null,
            autoplay: this.props.commentUpdate === true ? this.props.commentForUpdate.getAutoPlay : false,
            stopWhenReached: this.props.commentUpdate === true ? this.props.commentForUpdate.getStopWhenReached : false,
            allowReplies: this.props.commentUpdate === true ? this.props.commentForUpdate.getAllowReplies : false,
            commentVisibilityStatus: this.props.commentUpdate === true ? this.props.commentForUpdate.getVisibility === "public" ? true : false : true,
            commentBoxPositions: this.props.commentBoxPositions,
            left: this.props.commentBoxAnnotation.left,
            top: this.props.commentBoxAnnotation.top,
            saveError: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.switchSide !== this.props.switchSide) {
            this.setState({ switchSide: this.props.switchSide });
        }
        if (prevState.commentBoxAnnotation !== this.props.commentBoxAnnotation) {
            const { commentBoxAnnotation } = this.state;

            this.setState({ commentBoxAnnotation: this.props.commentBoxAnnotation })
            const topPositionValue =
                commentBoxAnnotation &&
                    (parseFloat(commentBoxAnnotation.top) < 10 || parseFloat(commentBoxAnnotation.top) > 70)
                    ? true
                    : false;
            const sideValue =
                commentBoxAnnotation && parseFloat(commentBoxAnnotation.left) > 55
                    ? "right"
                    : "left";
            const topBottomValue =
                commentBoxAnnotation &&
                    parseFloat(commentBoxAnnotation.top) > 70
                    ? "bottom"
                    : "top";
            this.setState({
                initSide: sideValue,
                switchSide: sideValue,
                topBottomPosition: topBottomValue,
                topPosition: topPositionValue,
                left: this.props.commentBoxAnnotation.left,
                top: this.props.commentBoxAnnotation.top
            });
        }

        if (
            prevProps.commentAnnotationContainerWidth !==
            this.props.commentAnnotationContainerWidth
        ) {
            if (
                this.props.commentAnnotationContainerWidth < 1000 &&
                parseFloat(this.props.commentBoxAnnotation.left) < 70
            )
                this.setState({ switchSide: "left" });
            else this.setState({ switchSide: this.state.initSide });
        }
    }

    componentDidMount() {
        const { commentBoxAnnotation } = this.state;

        // Check if comment is added close to top then return true/false
        const topPositionValue =
            commentBoxAnnotation &&
                (parseFloat(commentBoxAnnotation.top) < 10 || parseFloat(commentBoxAnnotation.top) > 70)
                ? true
                : false;
        const sideValue =
            commentBoxAnnotation && parseFloat(commentBoxAnnotation.left) > 55
                ? "right"
                : "left";
        const topBottomValue =
            commentBoxAnnotation &&
                parseFloat(commentBoxAnnotation.top) > 70
                ? "bottom"
                : "top";
        this.setState({
            initSide: sideValue,
            switchSide: sideValue,
            topBottomPosition: topBottomValue,
            topPosition: topPositionValue,
        });

        if (
            this.props.commentAnnotationContainerWidth < 1000 &&
            parseFloat(commentBoxAnnotation.left) < 70
        ) {
            this.setState({ switchSide: "left" });
        }
    }

    handleSaveButonClick = (event) => {
        this.setState({ saveButtonClick: event });
        this.props.setCommentLoad(true)
    };

    addToMedias = (mediaObject) => {
        this.setState((prevState) => ({
            commentMedias: [...prevState.commentMedias, mediaObject]
        }));
    }

    handleSelectedCategory = (category) => {
        this.setState({ selectedCategory: category })
    }

    handleAutoplaySettings = (status) => {
        this.setState({ autoplay: status })
    }

    handleStopWhenReached = (status) => {
        this.setState({ stopWhenReached: status })
    }

    handleAllowReplies = (status) => {
        this.setState({ allowReplies: status })
    }

    setSaveError = (status) => {
        if (status === true) this.props.setCommentLoad(false)
        this.setState({ saveError: status })
    }

    handleSetCommentStatus = (status) => {
        this.setState({ commentVisibilityStatus: status });
    }
    render() {
        const { switchSide, topBottomPosition, topPosition } = this.state;

        return (
            <div className='comment-create-container' style={{
                left: this.state.left,
                top: this.state.top,
            }}>
                <div className={`comment-anchor-${switchSide}`}>
                    <span
                        className={`location-icon-${switchSide} ${this.state.selectedCategory !== null && this.state.selectedCategory.icon + "-location-color"} ${topPosition ? `location-icon-${switchSide}-${topBottomPosition}` : ``
                            }`}>
                    </span>
                </div>
                <div
                    className={`comment-content-block comment-content-block-${switchSide} ${this.state.selectedCategory !== null && this.state.selectedCategory.icon + "-" + switchSide + "-border-color"} comment-content-block-${switchSide}-border-create-comment ${topPosition
                        ? `comment-content-block-${topBottomPosition}-${switchSide}` 
                        : ``
                        }`}
                >
                    <CommentDetials
                        setToastMessage={this.props.setToastMessage}
                        commentEdit={true}
                        user={this.props.user}
                        companyUsers={this.props.companyUsers}
                        saveButtonClick={this.state.saveButtonClick}
                        commentMedias={this.state.commentMedias}
                        videoID={this.props.videoID}
                        commentBoxPositions={this.props.commentBoxPositions}
                        player={this.props.player}
                        setAddComment={this.props.setAddComment}
                        filterCategories={this.props.filterCategories}
                        handleSelectedCategory={this.handleSelectedCategory}
                        closeCommentCreate={this.props.closeCommentCreate}
                        autoplay={this.state.autoplay}
                        stopWhenReached={this.state.stopWhenReached}
                        allowReplies={this.state.allowReplies}
                        getClickedComment={this.props.getClickedComment}
                        commentUpdate={this.props.commentUpdate}
                        commentForUpdate={this.props.commentForUpdate}
                        commentForReply={this.props.commentForReply}
                        setUpdateMediaFile={this.props.setUpdateMediaFile}
                        updateMediaFile={this.props.updateMediaFile}
                        setChangesMade={this.props.setChangesMade}
                        commentVisibilityStatus={this.state.commentVisibilityStatus}
                        setSaveError={this.setSaveError}
                        switchSide={switchSide}
                    />
                    <CommentFooter
                        commentEdit={true}
                        user={this.props.user}
                        addToMedias={this.addToMedias}
                        commentMedias={this.state.commentMedias}
                        handleAutoplaySettings={this.handleAutoplaySettings}
                        handleStopWhenReached={this.handleStopWhenReached}
                        handleAllowReplies={this.handleAllowReplies}
                        handleSetCommentStatus={this.handleSetCommentStatus}
                        commentUpdate={this.props.commentUpdate}
                        commentForUpdate={this.props.commentForUpdate}
                        commentForReply={this.props.commentForReply}
                        setUpdateMediaFile={this.props.setUpdateMediaFile}
                        updateMediaFile={this.props.updateMediaFile}
                        switchSide={switchSide}
                    />
                </div>
                <CommentAction
                    commentEdit={true}
                    switchSide={switchSide}
                    topPosition={topPosition}
                    topBottomPosition={topBottomPosition}
                    handleSaveButonClick={this.handleSaveButonClick}
                    closeCommentCreate={this.props.closeCommentCreate}
                    saveError={this.state.saveError}
                    commentReply={this.props.commentReply}
                    closeCommentReply={this.props.closeCommentReply}
                    commentForReply={this.props.commentForReply}
                />
            </div>
        )
    }
}
export default withTranslation()(CommentCreateContainer)