/**
 *
 * @file ListInput.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component is a ListInput that appears to represent a dropdown or list selection input.
 * <br/>Example use age of code - {@tutorial ListInput}
 * @module ListInput
 *
 **/

import React from "react";
import ActionsMenu from "../Misc/ActionsMenu";
import { Spinner } from "flowbite-react";
import { withTranslation } from "react-i18next";
import MiscFunctions from "../../helpers/MiscFunctions";

class ListInput extends React.Component {
  constructor(props) {
    super(props);

    if (MiscFunctions.isNull(this.props.Elements) && MiscFunctions.isUndefined(this.props.preSelection)) {
      this.default = { label: this.props.t('general.no_value'), tag: "default" };
    } else if (
      MiscFunctions.isNull(this.props.Elements) &&
      !MiscFunctions.isUndefined(this.props.preSelection)
    ) {
      this.default = this.props.preSelection;
    } else if (MiscFunctions.isUndefined(this.props.preSelection)) {
      this.default = this.props.Elements[0];
    } else {
      this.default = this.props.preSelection;
    }

    //console.log(this.props.Elements, this.props.preSelection, this.default);
    this.state = {
      // put state variables here
      showBlock: false,
      Elements: this.props.Elements,
      selectedElement: this.default,
      preSelection: this.props.preSelection,
    };
  }
  clickElement(elem) {
    //elem = new PersongroupService(elem)
    // console.log(elem);
    if (!MiscFunctions.isNull(this.props.Elements)) {
      this.props.selectHandler(elem);
      this.setState({
        selectedElement: elem.getLabel,
        showBlock: !this.state.showBlock,
      });
    }
  }

  componentDidMount() {
    // let dummy=this.state.Elements;
    // if (dummy===null){
    //   dummy={label: "",
    //   tag: "empty"
    // }
    // this.setState({Elements: dummy})
    // console.log(this.state.Elements)
    // }
    //console.log(this.state.Elements);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.preSelection !== this.props.preSelection) {
      this.setState({ preSelection: this.props.preSelection });
    }
    // if (
    //   prevProps.Elements !== this.props.Elements || prevProps.Reset !== this.props.Reset || prevProps.preSelection !== this.props.preSelection
    // ) {

    //   if (this.props.Elements===null && this.props.preSelection===undefined){
    //     this.default={"label": "No Value", "tag": "default"};
    //   } else if (this.props.Elements===null && this.props.preSelection!==undefined) {
    //     this.default=this.props.preSelection;
    //   }
    //   else if (this.props.preSelection===undefined) {
    //     this.default=this.props.Elements[0];
    //   } else {
    //     this.default=this.props.preSelection;
    //   }

    //   console.log(this.props.Elements,this.state.selectedElement,this.default)
    //   this.setState({
    //     Elements: this.props.Elements,
    //     selectedElement: this.default
    //   });

    // }
  }

  render() {
    return (
      <div
        className="flex flex-row list-input-main-block"
      // style={{ minWidth: "250px" }}
      >
        <div className="flex flex-col">
          <label
            className={
              "text-sm font-medium text-white dark:text-white input_label content-admin-input-label"
            }
          >
            {this.props.label}
          </label>

          {this.props.Ready ? (
            <ActionsMenu
              options={this.props.Elements}
              onClick={(elem) => this.clickElement(elem)}
              preSelection={this.state.preSelection}
            />
          ) : (
            <Spinner
              style={{ height: "30px" }}
              color="success"
              aria-label="loading comments"
              size="sm"
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ListInput);
