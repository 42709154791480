import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class CommentAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commentView: this.props.commentDisplay ? true : false,
            commentEdit: this.props.commentEdit ? true : false,
            commentReply: this.props.commentReply ? true : false,
            checkCommentIsReply: this.props.checkCommentIsReply ? true : false,
        }
    }
    handleMiniSliderClick = () => {
        this.props.setMiniSlider(false)
        this.props.setFullSlider(true)
    }
    render() {
        const { topPosition, topBottomPosition, switchSide, left, top, miniSlider, clickedComment, user, actionBtnHeight } = this.props;
        return (
            <div className={`comment-action-${switchSide} ${topPosition ? `comment-action-${topBottomPosition}-${switchSide}` : `comment-action-center-${switchSide}`}`}
                style={{ height: actionBtnHeight }}
            >
                {this.state.commentView &&
                    <>
                        {(!miniSlider && clickedComment.getUserID === user.UserID) && (this.state.commentView && this.state.checkCommentIsReply===false) && <span className='comment-edit' onClick={() => this.props.handlecommentUpdate(clickedComment, switchSide, left, top)}></span>}
                        {(!miniSlider && clickedComment.getUserID === user.UserID) && this.state.checkCommentIsReply===true && (this.props.editCommentReply === false || this.props.commentForReply.id !== this.props.clickedComment.id) && <span className='comment-edit' onClick={() => this.props.handleEditCommentReply(clickedComment, switchSide, left, top, this.state.checkCommentIsReply)}></span>}
                        {(!this.props.commentReply && !miniSlider && clickedComment.getAllowReplies && this.state.commentView && !this.state.checkCommentIsReply) && <span className='comment-respond-to' onClick={() => {this.props.handleCommentReply(clickedComment, switchSide, left, top)}}></span>}
                        {miniSlider && < span className='comment-media-full-slider' onClick={() => this.handleMiniSliderClick()}></span>}
                    </>
                }
                {this.state.commentEdit && 
                    <>
                        {this.state.commentEdit && <span className={`comment_close_icon ${this.props.saveError ? 'error' : ''}`} onClick={() => this.props.closeCommentCreate()}></span>}
                        {this.state.commentEdit && <span className={`comment_check_icon ${this.props.saveError ? 'error' : ''}`} onClick={this.props.handleSaveButonClick}></span>}
                    </>
                }
                {this.state.commentReply && this.props.checkCommentIsReply===false &&
                    <>
                        {this.state.commentReply && <span className={`comment_close_icon ${this.props.saveError ? 'error' : ''}`} onClick={() => this.props.closeCommentReply()}></span>}
                        {this.state.commentReply && <span className={`comment_check_icon ${this.props.saveError ? 'error' : ''}`} onClick={this.props.handleSaveButonClick}></span>}
                    </>
                }
                {this.props.editCommentReply===true && this.props.checkCommentIsReply===true && this.props.commentForReply.id === this.props.clickedComment.id && 
                    <>
                        {this.props.commentEdit && <span className={`comment_close_icon ${this.props.saveError ? 'error' : ''}`} onClick={() => this.props.closeCommentReplyEdit()}></span>}
                        {this.props.commentEdit && <span className={`comment_check_icon ${this.props.saveError ? 'error' : ''}`} onClick={this.props.handleSaveButonClick}></span>}
                    </>

                }
            </div>
        )
    }
}

export default withTranslation()(CommentAction)