/**
 * @file RessourceObject_PersonInput.js
 * @created_date Thursday, September 30, 2023
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description The `RessourceObject_PersonInput` component is a React form designed for inputting and submitting information about persons within the application. It features fields for the person's name, associated person group, description, and education details. The component communicates with the server through `ressourceAPI.postRessource` and `persongroupAPI.getPersonGroups` for real-time submission and fetching of person group options.
 *<br/>Breakdown of the code - {@tutorial RessourceObject_PersonInput}
 * @module RessourceObject_PersonInput
 *
 **/

import React from "react";
import ressourceObjectAPI from "../../api/ressourceObjectAPI";
import persongroupAPI from "../../api/persongroupAPI";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";

import StringInput from "./StringInput";
import ListInput from "./ListInput";
import TextInput from "./TextInput";
import { withTranslation } from "react-i18next";
import MiscFunctions from "../../helpers/MiscFunctions";

class RessourceObject_PersonInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // put state variables here
      name: "",
      description: "",
      education: "",
      persongroup: "",
      sendSuccess: null,
      persongroups: null,
      saveData: false,
    };
  }

  handleDescription = (string) => {
    console.log(string);
    this.setState({ description: string });
  };
  handleName = (string) => {
    this.setState({ name: string });
  };
  handlePersonGroups = (elem) => {
    console.log(elem)
    this.setState({ persongroup: elem });
  };
  handleEducation = (string) => {
    this.setState({ education: string });
  };

  handleSendSuccess = (e) => {
    this.setState({ sendSuccess: e });
  };

  handleSubmit = async () => {
    this.setState({ saveData: true });
    let data = {
      label: this.state.name,
      description: this.state.description,
      education: this.state.education,
      type: "PersonDto",
      persongroup: {
        "id": this.state.persongroup.getID,
        "tag": "persongroup"
      }};
    const res = await ressourceObjectAPI.postRessource(data);
    if (res.status === 200 || res.status === 201) {
      this.setState({
        saveData: false,
      });
      this.handleDescription("");
      this.handleName("");
      this.handleEducation("");
      this.handleSendSuccess(true);
    } else {
      this.handleSendSuccess(false);
    }
  };

  onClickOutsideListener = () => { };

  componentDidMount() {
    const res = persongroupAPI.getPersonGroups();
    res.then(async (response) => {
      this.setState({ persongroups: await response });
    });
  }
  componentWillUnmount() { }

  render() {
    return (
      <div>
        <div className="group-input-drop-down">
          <StringInput
            label={this.props.t('general.name')}
            setValue={this.handleName}
            value={this.state.name}
          />
          <ListInput
            label={this.props.t('general.person_group')}
            Elements={this.state.persongroups}
            selectHandler={this.handlePersonGroups}
            preSelection={this.state.persongroup}
            Ready={!MiscFunctions.isNull(this.state.persongroups)}
          />
        </div>
        <TextInput
          label={this.props.t('general.description')}
          rows="4"
          setValue={this.handleDescription}
          value={this.state.description}
        />
        <TextInput
          label={this.props.t('general.education')}
          rows="9"
          setValue={this.handleEducation}
          value={this.state.education}
        />
        <button
          onClick={() => {
            this.handleSubmit();
          }}
          className="content-admin-save-btn"
          disabled={
            !this.state.name || !this.state.education || !this.state.description
              ? true
              : false
          }
        >
          {this.props.t('general.enter')}
        </button>
        {this.state.sendSuccess === true && (
          <CheckBadgeIcon className="h-6 w-6" />
        )}
        {this.state.sendSuccess === false && (
          <XCircleIcon className="h-6 w-6" />
        )}
      </div>
    );
  }
}

export default withTranslation()(RessourceObject_PersonInput);
