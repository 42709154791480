/**
 *
 * @file mediaAPI.js
 * @created_date Friday, July 28, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description Here we defined a set of functions related to media authentication for the application, These functions are organized within the mediaAPI object, which is then exported for use in other parts of the application. The code also includes the import of utility functions for managing access tokens and an API client.
 * <br/>Example use age of code - {@tutorial mediaAPI}
 * @module mediaAPI
 *
 **/
import { getAuthAccessObject } from "../utils/tokenStorage";
import { apiClient } from 'my-api-client-package';
const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * @description This function sends a GET request to the /video/:id/process endpoint to retrieve video processing information. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: - {@tutorial mediaAPI}
 * @function getVideoProccessById
 * @return {void}
 * */
const getUploadLink = async (filename, description) => {
  if (description === "") description = filename;
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .get(API_BASE_URL, `/upload/${filename}/${description}`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description
 * @description This function
 * <br />Here you can find some examples: - {@tutorial mediaAPI}
 * @function getPatientVideoUploadLink
 * @return {void}
 * */
const getPatientVideoUploadLink = async (filename) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };
    return await apiClient
      .post(API_BASE_URL, `/patientvideo/${filename}`, {}, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        return resp;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

/**
 * @description This function sends a GET request to the /video/:id/process endpoint to retrieve video processing information. It includes the access token in the headers. It returns the response as text if the request is successful.
 * <br />Here you can find some examples: -
 * @function getMediaObject
 * @return {void}
 * */
const getMediaObject = async (filename, description) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
      recaptcha: getAuthAccessObject().recaptcha,
    };
    return await apiClient
      .get(API_BASE_URL, `/success/upload/${filename}`, customHeaders)
      .then(async function (response) {
        const resp = await response.json();
        if ("error" in resp) {
          console.log(resp)
          return []
        }
        else {
          return resp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
};

const getFileSize = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'HEAD', mode: 'no-cors'
    });
    if (response.ok) {
      const contentLength = response.headers.get('Content-Length');
      if (contentLength) {
        return parseInt(contentLength, 10);
      } else {
        throw new Error('Content-Length header is missing');
      }
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Failed to fetch file size:', error);
    return null;
  }
}

/**
 * @description This function sends a DELETE request to the /media/:id endpoint to retrieve delete a media file by id. It includes the access token in the headers. It returns the response as a parsed JSON object if the request is successful.
 * @function deleteMediaById
 * @return {void}
 */
const deleteMediaById = async(id) => {
  try {
    const customHeaders = {
      Authorization: `Bearer ${getAuthAccessObject().token}`,
    };
    return await apiClient
      .delete(API_BASE_URL, `/media/${id}`, customHeaders)
      .then(async function (response) {
        if ("error" in response) {
          return []
        }
        else {
          return response;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    console.error("Error: ", e);
  }
}
const mediaAPI = {
  getUploadLink,
  getMediaObject,
  getPatientVideoUploadLink,
  getFileSize,
  deleteMediaById
};

export default mediaAPI;