/**
 *
 * @file SwimlaneContainer.js
 * @created_date Wednesday, August 01, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component serves as a container for displaying a swimlane interface. It encapsulates the presentation of "PlayerSwimlaneInfoBar" and "SwimlaneZoomingBar". The purpose of this container is to arrange and manage the visual elements for the swimlane, which is part of the main video player interface.
 *<br/>Key functionalities - {@tutorial SwimlaneContainer}
 * @module SwimlaneContainer
 *
 **/

import React from "react";
import SwimlaneZoomingBar from "./SwimlaneZoomingBar";
import PlayerSwimlaneInfoBar from "./SwimlaneInfoBar";
import SwimlaneQuickAccessMenu from "./SwimlaneQuickAccessMenu";
import MiscFunctions from "../../helpers/MiscFunctions";
class SwimlaneContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomLevel: this.props.zoomLevel,
      persons: this.props.workflowParameters.person,
      persongroup: this.props.workflowParameters.persongroup,
      personsAndTasks: null,
      finalGroup: null,
      WorkflowTasks: props.WorkflowTasks,
      rangeSliderValue: this.props.rangeSliderValue,
    };
  }

  creatFinalGroup(WorkflowTasks) {
    if (MiscFunctions.isUndefined(WorkflowTasks)) return;
    const { persons, persongroup } = this.state;
    const personsTasks = persons.map((person) => {
      let tasks = WorkflowTasks.getPersonTasks(person);
      return { ...person, tasks };
    });

    this.setState({ personsAndTasks: personsTasks });
    const groupPersons = persongroup.map((group) => {
      let persons = WorkflowTasks.getGroupPersons(personsTasks, group);
      return { ...group, persons };
    });
    return groupPersons;
  }

  componentDidMount() {
    this.setState({
      finalGroup: this.creatFinalGroup(this.state.WorkflowTasks),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //BugFix: Recalculating the final group is also triggered by a change of persons and persongroup
    if (
      prevProps.WorkflowTasks !== this.props.WorkflowTasks ||
      prevState.persons !== this.state.persons ||
      prevState.persongroup !== this.state.persongroup
    ) {
      this.setState({ WorkflowTasks: this.props.WorkflowTasks });
      this.setState({
        finalGroup: this.creatFinalGroup(this.props.WorkflowTasks),
      });
    }

    if (prevState.personsAndTasks !== this.state.personsAndTasks) {
      this.setState({ personsAndTasks: this.state.personsAndTasks });
    }
    if (prevProps.workflowParameters !== this.props.workflowParameters) {
      this.setState({
        persons: this.props.workflowParameters.person,
        persongroup: this.props.workflowParameters.persongroup,
      });
    }
    if (prevState.finalGroup !== this.state.finalGroup) {
      this.setState({ finalGroup: this.state.finalGroup });
    }
    if (prevProps.rangeSliderValue !== this.props.rangeSliderValue) {
      this.setState({ rangeSliderValue: this.props.rangeSliderValue });
    }
  }

  render() {
    let finalGroup = this.state.finalGroup;
    if (!MiscFunctions.isNull(this.props.sortOption)) {
      this.state.finalGroup.filter((group) => {
        const filteredPersons = group.persons.filter(
          (person) => person.label === this.props.sortOption.label
        );
        return filteredPersons.length > 0;
      });
    }

    return (
      <>
        <div className="swimlane-container">
          <PlayerSwimlaneInfoBar
            videoObject={this.props}
            finalGroup={finalGroup}
            showIntroPopup={this.props.showIntroPopup}
            handleOpenIntroPopup={this.props.handleOpenIntroPopup}
          />
          <SwimlaneZoomingBar
            WorkflowPhases={this.props.WorkflowPhases}
            zoomLevel={this.props.zoomLevel}
            player={this.props.player}
            rangeSliderValue={this.state.rangeSliderValue}
            handlingZoomScroll={this.props.handlingZoomScroll}
            ProgressBarPosition={this.props.ProgressBarPosition}
            finalGroup={finalGroup}
            searchKeyword={this.props.searchKeyword}
            isSearching={this.props.isSearching}
            selectedVideo={this.props.selectedVideo}
            persons={this.state.persons}
          />
          <SwimlaneQuickAccessMenu />
        </div>
      </>
    );
  }
}

export default SwimlaneContainer;
