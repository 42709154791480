/**
 * @file QuickOverviewPopupsContainer.js
 * @created_date Thursday, November 30, 2023
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description This component serves as a container for managing the data flow and interactions related to displaying the quick overview popups. It fetches video resources, processes data, updates the state, and conditionally renders the QuickOverviewPopups component based on the visibility condition.
 *<br/>Breakdown of the code - {@tutorial QuickOverviewPopupsContainer}
 * @module QuickOverviewPopupsContainer
 *
 **/

import React from "react";
import QuickOverviewPopups from "./QuickOverviewPopups";
import contextAPI from "../../api/contextAPI";
import Context from "../../services/ContextServices";
import { WorkFlowInfoData } from "../../assets/data/WorkflowInfoPage";
import Ressource from "../../services/RessourceServices";
import { withTranslation } from "react-i18next";
import MiscFunctions from "../../helpers/MiscFunctions";

class QuickOverviewPopupsContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      VideoObject: props.VideoObject,
      workflow: props.workflow,
      newWorkFlowInfo: structuredClone(WorkFlowInfoData),
      video: null,
      ressources: props.ressources,
      // persongroup: props.workflowParameters.persongroup,
      // person: props.workflowParameters.person,
      groupPersons: null,
      dataReady: false,
      showIntroPopup: props.showIntroPopup,
      loadingSpiner: true,
      context: null
    };
  }
  handleStorage = () => {
    this.setState({
      introPopupShown: localStorage.getItem("hasSeenIntroductionPopup"),
    });
  };

  uniqueLabel(objects) {
    const uniqueObjects = {};

    objects.forEach((obj) => {
      if (!uniqueObjects[obj.label]) {
        uniqueObjects[obj.label] = obj;
      }
    });
    return Object.values(uniqueObjects);
  }

  resourceDataMap(resource, type) {
    let Data = {
      id: resource.getID,
      type: type,
      label: resource.getLabel,
      description: resource.getDescription,
      picture: resource.getPicture,
    };
    return Data;
  }

  infoDataMap(newWorkFlowInfo, video, i, label, string) {
    newWorkFlowInfo.infos[0].info.push({
      label: label,
      description: video.contexts[i].getValueByString(string),
    });
    return newWorkFlowInfo;
  }

  componentDidMount() { }

  componentWillUnmount() {
    this.setState({ newWorkFlowInfo: WorkFlowInfoData });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.introPopupShown !==
      localStorage.getItem("hasSeenIntroductionPopup")
    ) {
      this.setState({
        introPopupShown: localStorage.getItem("hasSeenIntroductionPopup"),
      });
    }
    if (prevState.newWorkFlowInfo !== this.state.newWorkFlowInfo) {
      this.setState({
        newWorkFlowInfo: this.state.newWorkFlowInfo,
      });
    }

    if (prevProps.VideoObject !== this.state.VideoObject) {
      this.setState({ VideoObject: this.props.VideoObject });
    }

    if (prevProps.workflow !== this.state.workflow) {
      this.setState({ workflow: this.props.workflow });
    }
    if (prevProps.showIntroPopup !== this.props.showIntroPopup) {
      this.setState({ showIntroPopup: this.props.showIntroPopup });
    }

    if(prevProps.VideoObject !== this.props.VideoObject) {
      let video = this.state.VideoObject;
      let newWorkFlowInfo = this.state.newWorkFlowInfo;
        if (!MiscFunctions.isNull(video.contexts)) {
          // console.log(video.contexts)
            contextAPI.getContextById(video.contexts[video.contexts.length-1].id).then((context) => {
              if (!MiscFunctions.isUndefined(context)) {
                // video.contexts[i] = new Context(context);
                let newContext = new Context(context);
                this.setState({ context: newContext })

                if (newContext.type === "Workflow-Introduction") {
                  newWorkFlowInfo.WorkflowName = video.getLabel;
                  newWorkFlowInfo.WorkflowId = video.getVideoID;

                  newWorkFlowInfo.infos[0].info = [
                    {
                      label: this.props.t('quick_overview_popups.abstract'),
                      description: newContext.getValueByString("abstract"),
                    },
                  ];

                  newWorkFlowInfo.infos[0].info.push({
                    label: this.props.t('quick_overview_popups.introduction'),
                    description: newContext.getValueByString("intro"),
                  });

                  newWorkFlowInfo.infos[0].info.push({
                    label: this.props.t('quick_overview_popups.indication'),
                    description: newContext.getValueByString("indication"),
                  });

                  newWorkFlowInfo.infos[0].info.push({
                    label: this.props.t('quick_overview_popups.contraindication'),
                    description:
                      newContext.getValueByString("contraindication"),
                  });

                  newWorkFlowInfo.infos[0].info.push({
                    label: this.props.t('quick_overview_popups.preoperative_preparation'),
                    description: newContext.getValueByString("preparation"),
                  });

                  newWorkFlowInfo.infos[0].info.push({
                    label: this.props.t('quick_overview_popups.postoperative_care'),
                    description: newContext.getValueByString("care"),
                  });
                }
                else if (newContext.type === "Questionary-Introduction") {
                  newWorkFlowInfo.WorkflowName = video.getLabel;
                  newWorkFlowInfo.WorkflowId = video.getVideoID;

                  newWorkFlowInfo.infos[0].info = [
                    {
                      label: this.props.t('quick_overview_popups.abstract'),
                      description: newContext.getValueByString("abstract"),
                    },
                  ];
                  newWorkFlowInfo.infos[1].info = null
                }
                else if (newContext.type === "Workflow-Overview") {
                  newWorkFlowInfo.infos[1].info = [
                    {
                      label: this.props.t('quick_overview_popups.workflow_phases'),
                      description: newContext.getValueByString("phases"),
                    },
                  ];
                  newWorkFlowInfo.infos[1].info.push({
                    label: this.props.t('quick_overview_popups.main_tasks'),
                    description: newContext.getValueByString("tasks"),
                  });
                }

                newWorkFlowInfo.resources = this.uniqueLabel(
                  newWorkFlowInfo.resources
                );

                this.setState({
                  loadingSpiner: false,
                  newWorkFlowInfo: newWorkFlowInfo,
                });
              }
              if (MiscFunctions.isUndefined(context)) {
                this.setState({ loadingSpiner: false });
              }
            });
        } else {
          this.setState({ loadingSpiner: false });
        }
    }
    if (prevProps.ressources !== this.props.ressources) {
      this.setState({ ressources: this.props.ressources }, () => {
        let newWorkFlowInfo = this.state.newWorkFlowInfo;

        // let persons = [];
        // let persongroups = [];
        if (this.state.ressources.length > 0) newWorkFlowInfo.resources = [];

        this.state.ressources.map((ressource) => {
          let resource = new Ressource(ressource);
          if (resource.getReferenceType === "PersonDto") {
            let Data = {
              id: resource.getID,
              type: resource.getReference.persongroup.label,
              label: resource.getLabel,
              description: resource.getDescription,
              picture: resource.getPicture,
            };
            newWorkFlowInfo.resources.push(Data);
            // persons.push(resource);
          } else if (resource.getReferenceType === "RoomDto") {
            newWorkFlowInfo.resources.push(
              this.resourceDataMap(resource, "Rooms")
            );
          } else if (
            resource.getReferenceType === "ToolDto" &&
            resource.getReference.category === "tool"
          ) {
            newWorkFlowInfo.resources.push(
              this.resourceDataMap(resource, "Tools")
            );
          } else if (
            resource.getReferenceType === "ToolDto" &&
            resource.getReference.category === "equipment"
          ) {
            newWorkFlowInfo.resources.push(
              this.resourceDataMap(resource, "Equipment")
            );
          } else if (
            resource.getReferenceType === "ToolDto" &&
            resource.getReference.category === "pharmaceutical"
          ) {
            newWorkFlowInfo.resources.push(
              this.resourceDataMap(resource, "Pharmaceuticals")
            );
          }
        });
        // Is Filtering the persongroups from the identified persons TODO: Needs to be put into a service group
        // persons.map((person) => {
        //   if (
        //     persongroups.find(
        //       (persongroup) =>
        //         persongroup.id === person.reference.persongroup.id
        //     ) === undefined
        //   ) {
        //     persongroups.push(person.reference.persongroup.label);
        //   }
        // });

        const personsGroups = this.props.personsGroup.map((item) => item.label);

        newWorkFlowInfo.rooms_equipmentGroups = ["rooms", "equipment"];
        newWorkFlowInfo.tool_pharmaGroups = ["tools", "pharmaceuticals"];
        newWorkFlowInfo.personGroups =
          personsGroups.length > 0 ? personsGroups : ["default_person_group"];
        // newWorkFlowInfo.personGroups =
        //   persongroups.length > 0 ? persongroups : ["default_person_group"];

        window.addEventListener("storage", this.handleStorage);
        this.setState({
          introPopupShown: localStorage.getItem("hasSeenIntroductionPopup"),
          context: false
        });
      });
    }
  }
  render() {
    return (
      this.state.showIntroPopup && (
        <QuickOverviewPopups
          WorkFlowInfo={this.state.newWorkFlowInfo}
          showIntroPopup={this.state.showIntroPopup}
          handleCloseIntroPopup={this.props.handleCloseIntroPopup}
          loadingSpiner={this.state.loadingSpiner}
          context={this.state.context}
        />
      )
    );
  }
}

export default withTranslation()(QuickOverviewPopupsContainer);
