/**
 * @file Icon.js
 * @created_date Thursday, December 21, 2022
 * @author Enrico Pannicke <enrico.pannicke@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The purpose of the Icon_Click and Icon components is to display icons with labels in a user interface. These components allow for interactive and non-interactive icons to be rendered and provide functionality for handling user interactions.
 *<br/>Breakdown of the code - {@tutorial Icon}
 * @module Icon
 *
 **/

import React from "react";
import MiscFunctions from "../../helpers/MiscFunctions";

class Icon_Click extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // put state variables here
      focus: this.props.category.status,
      mouseOver: false,
    };

    if (!MiscFunctions.isUndefined(this.props.static)) {
      this.static = this.props.static;
    } else {
      this.static = false;
    }
  }

  // Ensure Update of the component if input status is changing!
  componentDidUpdate(prevProps, prevState) {
    if (prevState.focus !== this.props.category.status) {
      this.setState({ focus: this.props.category.status });
    }
  }

  toggleFocus() {
    if (!this.static) {
      this.props.changeState(this.props.category.label, !this.state.focus);
      this.setState({ focus: !this.state.focus });
    }
  }

  render() {
    // item.getClasses = e.target.name + 'BgColor';
    return (
      <div
        className="flex py-1 px-2 rounded category_menu_item"
        key={this.props.keyName}
      >
        <div
          className="flex"
          onClick={() => {
            this.toggleFocus();
          }}
          onMouseOver={() => {
            this.setState({ mouseOver: true });
          }}
          onMouseOut={() => {
            this.setState({ mouseOver: false });
          }}
        >
          <div
            className={
              (this.state.focus || this.state.mouseOver
                ? this.props.category.icon + "BgColor"
                : "") + " container_icon"
            }
          >
            <span
              className={`all-category-icon comment_icon_${this.props.category.icon}${this.state.focus ? "_selected" : ""}`}
            ></span>
          </div>

          <div
            className={
              (this.state.focus ? this.props.category.icon + "BgColor" : "") &&
              "changeMenuItemSize"
            }
          >
            <span
              id={"createComment_" + this.props.category.icon + "_label"}
              className="category_checkbox_label"
              htmlFor={"createComment_" + this.props.category.label}
            >
              {this.props.category.label}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

class Icon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // selectedTagsHover: false,
      focus: this.props.category.status,
    };
  }

  toggleFocus() {
    if (!this.static) {
      this.props.changeState(this.props.category.label, !this.state.focus);
      this.setState({ focus: !this.state.focus });
    }
  }
  render() {
    let cat_icon = "comment";
    if (!MiscFunctions.isUndefined(this.props.category.icon)) {
      cat_icon = this.props.category.icon;
    }

    return (
      <div
        className={""}
        // onMouseOver={() => {
        //   this.setState({ selectedTagsHover: true });
        // }}
        // onMouseOut={() => {
        //   this.setState({ selectedTagsHover: false });
        // }}
        key={this.props.category.label}
      >
        <div className={"sidebar-icon-box"}>
          {/* <span className={"sidebar-categories-icon icon_" + cat_icon}></span> */}
          <span className={`${cat_icon}BgColor comment-details-selected-category`}>
            <span className={`${cat_icon}BgColor comment_icon_${cat_icon}_selected`}></span>
          </span>
          {/* {this.state.selectedTagsHover && this.props.category.status && ( */}
          {this.props.category.status && (
            <>
              <span className="selected_tag_text">
                {this.props.category.label}
              </span>
              {this.props.category.label !== "All" && (
                <span
                  className="selected_tag_close"
                  onClick={() => {
                    this.toggleFocus();
                  }}
                ></span>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export { Icon_Click, Icon };
//export default Icon;
