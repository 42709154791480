/**
 *
 * @file MiscFunctions.js
 * @created_date Wednesday, December 28,2022
 * @author Rafi Haidari <r.haidari@medimesh.de>
 * @Copyright © 2022 mediMESH. All rights reserved.
 * @description The module containing the utility functions serves as a collection of miscellaneous functions that can be used across different parts of the application. These functions are designed to perform specific tasks or provide helpful functionality that can be reused in various contexts.
 * <br/>Example use age of code - {@tutorial MiscFunctions}
 * @module MiscFunctions
 *
 **/

import userAPI from "../api/userAPI";
import userImage from "../assets/img/normal_control_icon_user_account.svg";
import Workflow from "../services/WorkflowService";

// Below method is for email validation.
const emailValidation = (input) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!input || regex.test(input) === false) {
    return false;
  }
  return true;
};

const checkIfSpecialCharacterExist = (input) => {
  var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return format.test(input);
};

const gotoTimestamp = (val, player) => {
  while (document.getElementsByClassName("vjs-poster").length > 0) {
    document
      .getElementsByClassName("vjs-poster")[0]
      .parentNode.removeChild(document.getElementsByClassName("vjs-poster")[0]);
  }
  player.current.currentTime(val + 0.001);
};

const Dates2CardFormat = (creationDate) => {
  let month = [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez",
  ];
  let DateSplit = creationDate.split("T");
  let oldDate = DateSplit[0].split("-").reverse();
  let oldTime = DateSplit[1].split(":");
  let newCreationDate =
    oldDate[0] +
    "." +
    month[Number(oldDate[1]) - 1] +
    "." +
    oldDate[2] +
    "\n" +
    oldTime[0] +
    ":" +
    oldTime[1];

  return newCreationDate;
};

const DateString2Seconds = (DateString) => {
  if (!MiscFunctions.isUndefined(DateString) && DateString !== "") {
    let seconds = 0;
    DateString.split(":").map((elem, i) => {
      seconds += Number(elem) * Math.pow(60, 2 - i);
    });
    return Math.floor(seconds);
  }
  return null;
};
const customTimeFormat = (seconds, guide) => {
  seconds = seconds < 0 ? 0 : seconds;
  let s = Math.floor(seconds % 60);
  let m = Math.floor((seconds / 60) % 60);
  let h = Math.floor(seconds / 3600);
  let gm = Math.floor((guide / 60) % 60);
  let gh = Math.floor(guide / 3600); // handle invalid times

  if (isNaN(seconds) || seconds === Infinity) {
    // '-' is false for all relational operators (e.g. <, >=) so this setting
    // will add the minimum number of fields specified by the guide
    h = m = s = "-";

    return h + ":" + s;
  } // Check if we need to show hours

  h = h > 0 || gh > 0 ? h + "" : ""; // If hours are showing, we may need to add a leading zero.
  // Always show at least one digit of minutes.

  m = (h || gm >= 10) && m < 10 ? "0" + m : m; // Check if leading zero is need for seconds

  h = parseInt(h) < 10 ? "0" + h : h;
  s = parseInt(s) < 10 ? "0" + s : s;

  if (h > 0) return `${h}h ${m}m ${s}s`;
  else return `${m}m ${s}s`;
};

const secondsMinuteFormat = (whereYouAt) => {
  let hours = Math.floor(whereYouAt / 3600);
  var minutes = Math.floor((whereYouAt / 60) % 60);
  var seconds = Math.floor(whereYouAt % 60);

  var h = hours < 10 ? `0${hours}` : minutes;
  var x = minutes < 10 ? `0${minutes}` : minutes;
  var y = seconds < 10 ? `0${seconds}` : seconds;

  return h + ":" + x + ":" + y + ".000";
};

const checkCommentText = (string) => {
  // while (string.charAt(string.length-1)==="\n") {
  //     string=string.slice(0,-1);
  // }
  string = string
    .replace(/\n/g, " &/n ")
    .replace(/"/g, "&quot")
    .replace(/'/g, "&apos");

  return string;
};

const formatText = (string) => {
  if (string.length > 0) {
    const pattern = /\@\[(.*?)\]/g;
    //const result = string.match(pattern);
    string = string.replace(/&quot/g, '"').replace(/&apos/g, "'");
    return string;
  } else {
    return string;
  }
};

const formatCommentText = (string) => {
  if (string !== null) {
    const pattern = /\@\[(.*?)\]/g;
    //const result = string.match(pattern);
    string = string.replace(/&quot/g, '"').replace(/&apos/g, "'");
    let match;
    const Out = [];
    let pos = 0;
    while (!MiscFunctions.isNull((match = pattern.exec(string)))) {
      // console.log(match)
      let objects = match[1].split(",");
      // console.log(objects)
      if (objects.length > 0) {
        if (match.index > 0) {
          Out.push({
            string:
              string.slice(pos, match.index - 1).replace(/\&\/n/g, "\n") + " ",
            type: "t",
            id: "",
          });
        }
        Out.push({
          string: objects[0] + " ",
          type: "p",
          id: objects[1],
        });
        pos = match.index + match[0].length + 1;
      }
    }

    Out.push({
      string: string.slice(pos).replace(/\&\/n/g, "\n"),
      type: "t",
      id: "",
    });



    //console.log(Out)

    return Out.map((elem) => {
      if (elem.type === "p") {
        return (
          <span
            className="user-tag"
            id={elem.id}
            onMouseOver={(ev) => {
              ev.target.className = "user-tag-hover";
            }}
            onMouseOut={(ev) => {
              ev.target.className = "user-tag";
            }}
          >
            {elem.string}
          </span>
        );
      } else if (elem.type === "t") {
        return <span className="text-tag" id={elem.id}
        >{elem.string}</span>;
      }
    });
  }
};

const createUserObject = (res) => {
  if (!MiscFunctions.isUndefined(res)) {
    let User = {
      UserID: res.id.id,
      role: res.role,
      display: res.firstname + " " + res.lastname,
      avatar: !MiscFunctions.isNull(res.avatar) ? res.avatar : userImage,
      UserCompany: MiscFunctions.isNull(res.company) ? null : res.company.id,
      notifications: res.notifications,
      favourites: res.favourites,
      preferences: res.preferences,
      languages: res.languages,
    };
    return User;
  } else {
    return null;
  }
};

function sortDataByLabel(data, sortOptions) {
  let encapsulatedData = data.map((el) => {
    return new Workflow(el);
  });
  switch (sortOptions) {
    case "az":
      encapsulatedData.sort((a, b) => a.getLabel.localeCompare(b.getLabel, undefined, {numeric: true}));
      break;
    case "za":
      encapsulatedData.sort((a, b) => b.getLabel.localeCompare(a.getLabel, undefined, {numeric: true}));
      break;
    case "date":
      encapsulatedData.sort(
        (a, b) =>
          !MiscFunctions.isNull(a.getCreationDate) &&
          a.getCreationDate.localeCompare(b.getCreationDate)
      );
      break;
    default:
      break;
  }
  return encapsulatedData;
}

function isValidJSON(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

function isUndefined(value) {
  // console.log("undef", value, typeof value, value === undefined)
  if (typeof value === "object") {
    if (value === undefined) {
      return value === undefined;
    } else {
      if (value.length === 0) {
        return value !== undefined;
      } else if (value.length === undefined) {
        return value === undefined;
      } else {
        return value === undefined;
      }
    }
  } else {
    return value === undefined;
  }
}
function isNull(value) {
  // console.log("null", value, typeof value, value === null)
  if (typeof value === "object") {
    if (value === null) {
      return value === null;
    } else {
      if (value.length === 0) {
        return value !== null;
      } else if (value.length === undefined) {
        return value === null;
      } else {
        return value === null;
      }
    }
  } else {
    return value === null;
  }
}

function areDatesDifferent(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  return date1.getTime() !== date2.getTime();
}

const changeLanguage = async (e) => {
  let languageTag = JSON.parse(localStorage.selected_language).tag;
  let languageTagNew;
  if (e.tag === "en") {
    languageTagNew = "english";
  } else if (e.tag === "de") {
    languageTagNew = "german";
  } else if (e.tag === "ja") {
    languageTagNew = "japanese";
  }
  let dataNew;
  let data;
  switch (languageTag) {
    case "en":
      data = await userAPI.setPreference("english");
      dataNew = await userAPI.setPreference(languageTagNew);
      break;
    case "de":
      data = await userAPI.setPreference("german");
      dataNew = await userAPI.setPreference(languageTagNew);
      break;
    case "ja":
      data = await userAPI.setPreference("japanese");
      dataNew = await userAPI.setPreference(languageTagNew);
      break;
  }
};
const MiscFunctions = {
  emailValidation,
  checkIfSpecialCharacterExist,
  gotoTimestamp,
  customTimeFormat,
  secondsMinuteFormat,
  DateString2Seconds,
  Dates2CardFormat,
  checkCommentText,
  formatCommentText,
  createUserObject,
  sortDataByLabel,
  formatText,
  isValidJSON,
  isUndefined,
  isNull,
  areDatesDifferent,
  changeLanguage,
};
export default MiscFunctions;
